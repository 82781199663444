import { library } from '@fortawesome/fontawesome-svg-core';

/**
 * Load icons from the "Regular" style.
 */
import { faXmark as faXmarkRegular } from '@fortawesome/pro-regular-svg-icons/faXmark';

library.add(faXmarkRegular);

/**
 * Load icons from the "Solid" style.
 */
import { faChevronRight as faChevronRightSolid } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

library.add(faChevronRightSolid);

/**
 * Load icons from the "Duotone" style.
 */
import { faCirclePlay as faCirclePlayDuotone } from '@fortawesome/pro-duotone-svg-icons/faCirclePlay';
import { faCirclePause as faCirclePauseDuotone } from '@fortawesome/pro-duotone-svg-icons/faCirclePause';
import { faSpinnerThird as faSpinnerThirdDuo } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';

library.add(
  faCirclePlayDuotone,
  faCirclePauseDuotone,
  faSpinnerThirdDuo,
);
